import Link from "components/common/Link";
import TextLink from "components/common/TextLink";
import { css } from "linaria";
import { kebabCase } from "lodash";
import React from "react";

import { getCategoryName } from "../../utils";

const postClass = css`
  max-width: 425px;
  width: 100%;

  a {
    display: block;
    text-decoration: none;
  }
`;

const postInnerClass = css`
  position: relative;
  background-color: white;
`;

const imageContainerClass = css`
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    height: 200px;
    transition: all 500ms ease;

    .${postClass}:hover & {
      transform: scale(1.03);
    }
  }
`;

const postContentClass = css`
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
  padding: 25px 0px 16px 0px;
  overflow: hidden;

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: rgba(43, 46, 48, 0.99);
  }
`;

const postTagsClass = css`
  flex: 0 0;
  display: flex;
  margin-top: 16px;
  font-size: 12px;

  span {
    color: #9eb1bc;
  }
`;

export default function RelatedBlogPostCard({ post }) {
  return (
    <div className={postClass}>
      <div className={postInnerClass}>
        <Link to={post.fields.slug} className={imageContainerClass}>
          <img
            src={
              post.frontmatter.featuredImages?.featuredImageThumbnail ??
              post.frontmatter.thumbnail
            }
            alt={
              post.frontmatter.featuredImages?.featuredImageAltText ??
              "blog post thumbnail"
            }
          />
        </Link>
        <div className={postTagsClass}>
          {post.frontmatter.category && (
            <React.Fragment key={post.frontmatter.category}>
              <TextLink
                to={`/blog/${kebabCase(
                  getCategoryName(post.frontmatter.category)
                )}/`}
              >
                {getCategoryName(post.frontmatter.category).toUpperCase()}
              </TextLink>
            </React.Fragment>
          )}
        </div>
        <div className={postContentClass}>
          <Link to={post.fields.slug}>
            <h2>{post.frontmatter.title}</h2>
          </Link>
        </div>
      </div>
    </div>
  );
}
