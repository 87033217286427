import { Link } from "gatsby";
import { styled } from "linaria/react";
import React, { useEffect, useState } from "react";

import RhombusButton from "../common/RhombusButton";
import { PreTitle, TextLink } from "../rhombus-UI/theme/typography";
import useHeadingObserver from "hooks/useHeadingObserver";

const TocWrapper = styled.div`
  width: ${props => (props.useFullWidth ? "100%" : "45%")};
  display: flex;
  height: 100%;
  position: relative;
  aside {
    width: 400px;
    min-height: 262px;
    height: fit-content;
    position: sticky;
    top: 7.5rem;
    box-shadow: 1px 1px 20px #00000020;
    border-radius: 8px;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    .divider {
      display: flex;
      align-items: center;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      li {
        font-weight: bold;
      }
    }
    .toc-footer {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      gap: 1.25rem;
    }
  }
`;

export const TocItem = styled.a`
  font-weight: 400;
  color: ${props => (props.active ? "var(--blue-500)" : "var(--nuetral-900)")};
  position: relative;
  text-decoration: none;
  transition: all 250ms ease-in-out;
  font-size: 16px;
  :hover {
    color: var(--blue-500);
  }
`;

const TableOfContents = ({
  contentID,
  useFullWidth = false,
  useCTAs = true,
  useTitle = true,
}) => {
  const [highlights, setHighlights] = useState([]);
  const { activeHeading } = useHeadingObserver();

  const createId = string => {
    if (!string) return null;
    return string
      .toLowerCase()
      .split(" ")
      .join("-");
  };

  useEffect(() => {
    const headings = Array.from(
      document.querySelectorAll(`#${contentID} h2`)
    ).map(el => {
      el.id = createId(el.innerText);
      return el.innerText;
    });
    setHighlights(headings);
  }, []);

  const handleTOCClick = (item, e) => {
    e.preventDefault();
    const id = createId(item);
    const target = document.getElementById(id);
    target.scrollIntoView({
      behavior: "smooth",
    });
  };

  if (highlights.length === 0) return <></>;

  return (
    <TocWrapper useFullWidth={useFullWidth}>
      <aside>
        {useTitle && (
          <div className="divider">
            <PreTitle style={{ textTransform: "unset", fontSize: "20px" }}>
              Table of Contents
            </PreTitle>
          </div>
        )}
        <ul>
          {highlights.map((item, index) => (
            <li>
              <TocItem
                href={`#${createId(item)}`}
                key={index}
                active={activeHeading === item}
                onClick={e => handleTOCClick(item, e)}
              >
                {item}
              </TocItem>
            </li>
          ))}
        </ul>
        {useCTAs && (
          <div className="toc-footer">
            <RhombusButton
              style={{ width: "100%", height: "fit-content" }}
              type="primary"
              path="/contact-sales/"
              title="Contact Sales"
            />
            <TextLink
              as={Link}
              to="/free-trial/"
              color="var(--blue-500)"
              style={{
                textAlign: "center",
                width: "100%",
                justifyContent: "center",
                fontSize: "16px",
                textDecoration: "underline",
                textTransform: "none",
              }}
            >
              Try Rhombus for Free!
            </TextLink>
          </div>
        )}
      </aside>
    </TocWrapper>
  );
};

export default TableOfContents;
