import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const IconLinkedIn = () => {
  return (
    <StaticImage
      alt="linked icon"
      src="./assets/linked-in.png"
      height={24}
      width={24}
    />
  );
};

export default IconLinkedIn;
