import "./Layout.css";

import React from "react";

import GlobalLayout from "../page/GlobalLayout";

const TemplateWrapper = ({ children }) => (
  <GlobalLayout>
    <div>
      <div>{children}</div>
    </div>
  </GlobalLayout>
);

export default TemplateWrapper;
