import { useEffect, useRef, useState } from "react";

export const useHeadingObserver = () => {
  const observer = useRef();
  const [activeHeading, setActiveHeading] = useState("");

  useEffect(() => {
    const handleObsever = entries => {
      entries.forEach(entry => {
        if (entry?.isIntersecting) {
          setActiveHeading(entry.target.outerText);
        }
      });
    };

    observer.current = new IntersectionObserver(handleObsever, {
      threshold: 1.0,
      rootMargin: "0px",
    });

    const elements = document.querySelectorAll("h2");
    elements.forEach(elem => observer.current.observe(elem));
    return () => observer.current?.disconnect();
  }, []);

  return { activeHeading };
};

export default useHeadingObserver;
