import Carrot from "components/common/icon-components/Carrot";
import Link from "components/page/headers/HeaderLink";
import { PreTitle, TextLink } from "components/rhombus-UI/theme/typography";
import useHeadingObserver from "hooks/useHeadingObserver";
import { css } from "linaria";
import { styled } from "linaria/react";
import React, { useEffect, useState } from "react";

import RhombusButton from "../common/RhombusButton";
import { TocItem } from "./TableOfContents";

const TocContainer = styled.div`
  background-color: var(--white);
  position: sticky;
  height: fit-content;
  padding: 1.25rem;
  padding-left: 2.5rem;
  top: 80px;
  margin-left: -2.5rem;
  width: calc(100% + 5rem);
  box-shadow: 1px 1px 10px #0b0c0d10;
  z-index: 10;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  scroll-margin-bottom: 10rem;
  aside {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    li {
      padding: 1.25rem 0;
      border-bottom: 1px solid var(--nuetral-200);
    }
    li:last-of-type {
      border-bottom: ${props =>
        props.useCTAs ? "1px solid var(--nuetral-200)" : "none"};
    }
  }
  .toc-footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1.25rem;
  }
  @media (max-width: 600px) {
    margin-left: -1.25rem;
    width: calc(100% + 2.5rem);
    padding-left: 1.25rem;
  }
`;

const TocDrawer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    transform: ${props => (props.active ? "" : "rotate(-90deg)")};
    transition: all 250ms ease-in-out;
  }
`;

const TableOfContentsMobile = ({ contentID, useCTAs = true }) => {
  const [active, setActive] = useState(false);
  const [highlights, setHighlights] = useState([]);
  const { activeElement } = useHeadingObserver();

  const createId = string => {
    if (!string) return null;
    return string
      .toLowerCase()
      .split(" ")
      .join("-");
  };

  useEffect(() => {
    const headings = Array.from(
      document.querySelectorAll(`#${contentID} h2`)
    ).map(el => {
      el.id = createId(el.innerText);
      return el.innerText;
    });
    setHighlights(headings);
  }, []);

  const handleTOCClick = (item, e) => {
    e.preventDefault();
    const id = createId(item);
    const target = document.getElementById(id);
    target.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    setActive(!active);
  };

  if (highlights.length === 0) return <></>;

  return (
    <TocContainer useCTAs={useCTAs}>
      <TocDrawer active={active} onClick={() => setActive(!active)}>
        <PreTitle style={{ textTransform: "unset" }}>
          Table of Contents
        </PreTitle>
        <Carrot width="20" height="10" />
      </TocDrawer>
      {active && (
        <aside>
          <ul>
            {highlights.map((item, index) => (
              <li>
                <TocItem
                  href={`#${createId(item)}`}
                  key={index}
                  active={activeElement === item}
                  onClick={e => handleTOCClick(item, e)}
                >
                  {item}
                </TocItem>
              </li>
            ))}
          </ul>
          {useCTAs && (
            <div className="toc-footer">
              <RhombusButton
                style={{ width: "100%", height: "fit-content" }}
                type="primary"
                path="/contact-sales/"
                title="Contact Sales"
              />
              <TextLink
                as={Link}
                to="/free-trial/"
                color="var(--blue-500)"
                style={{
                  textAlign: "center",
                  width: "100%",
                  justifyContent: "center",
                  fontSize: "16px",
                  textDecoration: "underline",
                  textTransform: "none",
                }}
              >
                Try Rhombus for Free!
              </TextLink>
            </div>
          )}
        </aside>
      )}
    </TocContainer>
  );
};

export default TableOfContentsMobile;
